import React from "react";

const MultipleOasi = ({ color }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M2.94449 4.52643C3.81295 4.51826 4.68141 4.5591 5.5416 4.62445C5.76492 4.64079 5.87244 4.75516 5.87244 4.98389C5.87244 5.21262 5.76492 5.31881 5.5416 5.33515C4.21823 5.43318 2.90314 5.46585 1.57977 5.4005C1.15795 5.38416 0.744394 5.35966 0.322571 5.32698C0.0992525 5.31064 0 5.19628 0 4.96755C0 4.74699 0.107524 4.64079 0.322571 4.62445C1.1993 4.5591 2.06776 4.51826 2.94449 4.52643Z" fill="#333333"/>
      <path d="M3.21762 6.47942C3.06047 6.47942 2.90332 6.47942 2.74617 6.47942C2.63864 6.47942 2.01005 6.45491 1.91906 6.44674C1.46416 6.41406 1.00925 6.35688 0.554342 6.27519C0.397192 6.25069 0.289668 6.16083 0.2235 6.01379C0.115976 5.74421 0.273126 5.53999 0.554342 5.5645C1.72056 5.67886 2.88678 5.6952 4.05299 5.65435C4.45 5.63802 4.84701 5.60534 5.24402 5.57266C5.5087 5.54816 5.64103 5.60534 5.67412 5.78506C5.71547 6.00562 5.58314 6.22618 5.35155 6.26702C5.06206 6.32421 4.77257 6.36505 4.48309 6.39773C4.05299 6.44674 3.63944 6.47942 3.21762 6.47942Z" fill="#333333"/>
      <path d="M2.43185 4.28329C1.82807 4.29963 1.22428 4.32414 0.620493 4.38132C0.380633 4.40583 0.248296 4.35681 0.206941 4.19344C0.157315 3.98921 0.256567 3.77682 0.463343 3.71147C0.59568 3.66245 0.736288 3.64612 0.876895 3.62978C2.31606 3.42556 3.75522 3.42556 5.18611 3.66245C5.52522 3.71964 5.68237 3.85851 5.6741 4.11991C5.66583 4.29963 5.55831 4.414 5.36807 4.40583C5.14475 4.38949 4.92144 4.37315 4.69812 4.34864C4.66503 4.34048 2.95293 4.26696 2.43185 4.28329Z" fill="#333333"/>
      <path d="M4.87119 3.40884C4.33357 3.34348 3.76287 3.29447 3.19217 3.2863C2.50567 3.27813 1.81917 3.31081 1.14095 3.3925C0.84319 3.42517 0.760479 3.38433 0.735666 3.22912C0.702582 3.03306 0.868003 2.82067 1.09132 2.77983C1.43043 2.71448 1.76955 2.6818 2.11693 2.66546C3.00193 2.61645 3.8952 2.63279 4.78021 2.77983C4.97044 2.8125 5.11932 2.95138 5.1524 3.12292C5.17722 3.30264 5.08623 3.417 4.87119 3.40884Z" fill="#333333"/>
      <path d="M2.84495 6.66911C3.50663 6.68545 4.17659 6.6446 4.83827 6.55474C4.94579 6.53841 5.05332 6.53841 5.11948 6.63643C5.19392 6.73446 5.16911 6.84066 5.11948 6.93868C5.05332 7.06939 4.94579 7.15925 4.79691 7.18375C3.90364 7.32262 3.01037 7.34713 2.10882 7.28995C1.77798 7.26544 1.44714 7.24094 1.1163 7.18375C0.901252 7.15108 0.744102 6.9877 0.735831 6.79981C0.72756 6.63643 0.826813 6.53024 0.992233 6.54658C1.14111 6.56291 1.28172 6.57925 1.4306 6.59559C1.56294 6.61193 2.62163 6.66094 2.84495 6.66911Z" fill="#333333"/>
      <path d="M2.72899 2.00658C3.17563 1.99841 3.55609 1.99024 3.92829 2.08827C4.0689 2.12094 4.20123 2.18629 4.31703 2.28432C4.3832 2.3415 4.40801 2.40685 4.3832 2.48037C4.35011 2.56206 4.28395 2.58657 4.20123 2.5784C4.01927 2.56206 3.82904 2.54573 3.64707 2.52939C2.91922 2.48037 2.19137 2.50488 1.47179 2.58657C1.36427 2.59474 1.25674 2.61108 1.21539 2.48037C1.17403 2.35784 1.26501 2.29249 1.34772 2.23531C1.5545 2.09643 1.78609 2.04742 2.02595 2.03108C2.28235 2.00658 2.53875 1.99024 2.72899 2.00658Z" fill="#333333"/>
      <path d="M2.94441 7.99105C2.57221 7.99922 2.19175 8.02373 1.81955 7.9257C1.67894 7.88485 1.53833 7.83584 1.43081 7.73781C1.36464 7.68063 1.32329 7.61528 1.35637 7.52542C1.39773 7.42739 1.48044 7.41106 1.57142 7.42739C2.10076 7.49275 2.63838 7.51725 3.16773 7.50908C3.53993 7.50091 3.90385 7.47641 4.27605 7.43556C4.3753 7.42739 4.46628 7.42739 4.51591 7.52542C4.56554 7.63162 4.49937 7.69697 4.42493 7.76232C4.20161 7.93387 3.94521 7.98288 3.67226 7.99922C3.4324 7.99922 3.18427 7.99105 2.94441 7.99105Z" fill="#333333"/>
      <path d="M8.47237 2.26419C8.90664 2.2601 9.3409 2.28053 9.77104 2.31321C9.8827 2.32138 9.93647 2.37856 9.93647 2.49294C9.93647 2.60731 9.8827 2.66042 9.77104 2.66859C9.1093 2.7176 8.45169 2.73394 7.78995 2.70126C7.57902 2.6931 7.37223 2.68084 7.1613 2.6645C7.04963 2.65633 7 2.59914 7 2.48477C7 2.37448 7.05377 2.32138 7.1613 2.31321C7.5997 2.28053 8.03397 2.2601 8.47237 2.26419Z" fill="#333333"/>
      <path d="M8.60894 3.23975C8.53036 3.23975 8.45177 3.23975 8.37319 3.23975C8.31943 3.23975 8.0051 3.22749 7.95961 3.22341C7.73213 3.20707 7.50466 3.17847 7.27719 3.13763C7.1986 3.12537 7.14484 3.08044 7.11175 3.00691C7.05798 2.87211 7.13657 2.76999 7.27719 2.78225C7.86034 2.83944 8.4435 2.8476 9.02666 2.82718C9.22518 2.81901 9.42371 2.80267 9.62223 2.78633C9.75458 2.77408 9.82075 2.80267 9.83729 2.89254C9.85797 3.00283 9.7918 3.11312 9.67599 3.13354C9.53124 3.16214 9.38648 3.18256 9.24173 3.1989C9.02666 3.22341 8.81987 3.23975 8.60894 3.23975Z" fill="#333333"/>
      <path d="M8.21651 2.14168C7.91459 2.14985 7.61267 2.1621 7.31075 2.1907C7.19081 2.20295 7.12464 2.17844 7.10396 2.09675C7.07914 1.99463 7.12877 1.88842 7.23217 1.85574C7.29835 1.83124 7.36866 1.82307 7.43897 1.8149C8.15861 1.71278 8.87825 1.71278 9.59376 1.83124C9.76333 1.85983 9.84191 1.92927 9.83777 2.05998C9.83364 2.14985 9.77987 2.20704 9.68475 2.20295C9.57308 2.19478 9.46141 2.18661 9.34974 2.17436C9.3332 2.17027 8.47707 2.13351 8.21651 2.14168Z" fill="#333333"/>
      <path d="M9.43626 1.70445C9.16743 1.67177 8.88205 1.64726 8.59668 1.64318C8.2534 1.63909 7.91012 1.65543 7.57098 1.69628C7.42209 1.71262 7.38073 1.6922 7.36832 1.61458C7.35178 1.51655 7.4345 1.41034 7.54616 1.38992C7.71573 1.35724 7.88531 1.3409 8.05901 1.33273C8.50155 1.30822 8.94823 1.31639 9.39076 1.38992C9.48589 1.40626 9.56033 1.4757 9.57688 1.56148C9.58929 1.65135 9.54379 1.70854 9.43626 1.70445Z" fill="#333333"/>
      <path d="M8.42305 3.33554C8.75392 3.34371 9.08893 3.32328 9.4198 3.27835C9.47356 3.27018 9.52733 3.27018 9.56042 3.3192C9.59764 3.36822 9.58523 3.42132 9.56042 3.47034C9.52733 3.53569 9.47356 3.58063 9.39912 3.59288C8.95244 3.66232 8.50577 3.67458 8.05496 3.64598C7.88952 3.63373 7.72409 3.62147 7.55865 3.59288C7.45112 3.57654 7.37254 3.49484 7.3684 3.40089C7.36427 3.3192 7.4139 3.26609 7.49662 3.27426C7.57106 3.28243 7.64137 3.2906 7.71582 3.29877C7.78199 3.30694 8.31138 3.33145 8.42305 3.33554Z" fill="#333333"/>
      <path d="M8.36456 1.00329C8.5879 0.999204 8.77815 0.995119 8.96426 1.04414C9.03457 1.06048 9.10074 1.09315 9.15865 1.14217C9.19173 1.17077 9.20414 1.20344 9.19173 1.24021C9.17519 1.28106 9.1421 1.29331 9.10074 1.28923C9.00976 1.28106 8.91463 1.27289 8.82364 1.26472C8.45968 1.24021 8.09573 1.25246 7.73591 1.29331C7.68214 1.2974 7.62837 1.30556 7.60769 1.24021C7.58701 1.17894 7.63251 1.14626 7.67387 1.11766C7.77727 1.04822 7.89307 1.02371 8.01301 1.01554C8.14122 1.00329 8.26943 0.995119 8.36456 1.00329Z" fill="#333333"/>
      <path d="M8.47276 3.99555C8.28665 3.99963 8.0964 4.01189 7.91028 3.96287C7.83997 3.94245 7.76966 3.91794 7.7159 3.86892C7.68281 3.84033 7.66213 3.80765 7.67867 3.76272C7.69935 3.7137 7.74071 3.70553 7.78621 3.7137C8.0509 3.74638 8.31973 3.75863 8.58443 3.75455C8.77054 3.75046 8.95252 3.73821 9.13864 3.71778C9.18827 3.7137 9.23376 3.7137 9.25858 3.76272C9.28339 3.81582 9.25031 3.8485 9.21308 3.88117C9.10141 3.96696 8.9732 3.99146 8.83672 3.99963C8.71678 3.99963 8.5927 3.99555 8.47276 3.99555Z" fill="#333333"/>
      <path d="M9.92632 8.36857C11.0844 8.35768 12.2424 8.41214 13.3894 8.49928C13.6872 8.52107 13.8306 8.67357 13.8306 8.97857C13.8306 9.28357 13.6872 9.42517 13.3894 9.44696C11.6248 9.57767 9.87118 9.62124 8.10654 9.5341C7.54406 9.51232 6.99261 9.47964 6.43013 9.43607C6.13235 9.41428 6 9.26178 6 8.95678C6 8.66268 6.14338 8.52107 6.43013 8.49928C7.5992 8.41214 8.75725 8.35768 9.92632 8.36857Z" fill="#333333"/>
      <path d="M10.2908 10.9727C10.0813 10.9727 9.87172 10.9727 9.66217 10.9727C9.5188 10.9727 8.68059 10.94 8.55927 10.9291C7.95268 10.8855 7.34608 10.8093 6.73949 10.7003C6.52994 10.6677 6.38656 10.5478 6.29833 10.3518C6.15495 9.9923 6.3645 9.71998 6.73949 9.75266C8.29458 9.90516 9.84967 9.92695 11.4048 9.87248C11.9341 9.8507 12.4635 9.80712 12.9929 9.76355C13.3459 9.73088 13.5223 9.80712 13.5664 10.0468C13.6216 10.3409 13.4451 10.635 13.1363 10.6894C12.7503 10.7657 12.3643 10.8202 11.9783 10.8637C11.4048 10.9291 10.8533 10.9727 10.2908 10.9727Z" fill="#333333"/>
      <path d="M9.2424 8.04513C8.43728 8.06692 7.63216 8.0996 6.82705 8.17585C6.50721 8.20853 6.33074 8.14317 6.2756 7.92531C6.20942 7.65299 6.34177 7.36978 6.6175 7.28264C6.79396 7.21728 6.98145 7.19549 7.16895 7.17371C9.08799 6.90139 11.007 6.90139 12.9151 7.21728C13.3672 7.29353 13.5768 7.47871 13.5658 7.82728C13.5547 8.06692 13.4114 8.21942 13.1577 8.20853C12.8599 8.18674 12.5621 8.16495 12.2643 8.13228C12.2202 8.12138 9.93722 8.02335 9.2424 8.04513Z" fill="#333333"/>
      <path d="M12.4957 6.87788C11.7788 6.79074 11.0178 6.72538 10.2568 6.71449C9.34142 6.7036 8.42601 6.74717 7.52163 6.8561C7.12459 6.89967 7.0143 6.84521 6.98121 6.63824C6.9371 6.37681 7.15768 6.0936 7.45546 6.03914C7.90765 5.95199 8.35984 5.90842 8.82306 5.88664C10.0032 5.82128 11.1943 5.84306 12.3744 6.03914C12.6281 6.08271 12.8266 6.26788 12.8707 6.49663C12.9038 6.73628 12.7825 6.88878 12.4957 6.87788Z" fill="#333333"/>
      <path d="M9.7935 11.2261C10.6758 11.2479 11.5692 11.1935 12.4515 11.0736C12.5949 11.0519 12.7382 11.0519 12.8265 11.1826C12.9257 11.3133 12.8927 11.4549 12.8265 11.5856C12.7382 11.7599 12.5949 11.8797 12.3963 11.9124C11.2052 12.0976 10.0141 12.1303 8.81192 12.054C8.37076 12.0213 7.9296 11.9886 7.48844 11.9124C7.20169 11.8688 6.99214 11.651 6.98111 11.4004C6.97008 11.1826 7.10243 11.041 7.32301 11.0628C7.52153 11.0845 7.70902 11.1063 7.90754 11.1281C8.08401 11.1499 9.49572 11.2153 9.7935 11.2261Z" fill="#333333"/>
      <path d="M9.6385 5.00877C10.2341 4.99788 10.7414 4.98698 11.2377 5.1177C11.4252 5.16127 11.6017 5.24841 11.7561 5.37913C11.8443 5.45538 11.8774 5.54252 11.8443 5.64055C11.8002 5.74948 11.712 5.78216 11.6017 5.77127C11.359 5.74948 11.1054 5.7277 10.8627 5.70591C9.89217 5.64055 8.92161 5.67323 7.96209 5.78216C7.81871 5.79305 7.67534 5.81484 7.62019 5.64055C7.56505 5.47716 7.68637 5.39002 7.79666 5.31377C8.07238 5.12859 8.38119 5.06323 8.70103 5.04145C9.04293 5.00877 9.38483 4.98698 9.6385 5.00877Z" fill="#333333"/>
      <path d="M9.92606 12.9894C9.42976 13.0003 8.92242 13.033 8.42612 12.9023C8.23863 12.8478 8.05113 12.7825 7.90776 12.6518C7.81952 12.5755 7.76438 12.4884 7.8085 12.3685C7.86364 12.2378 7.97393 12.216 8.09525 12.2378C8.80111 12.325 9.51799 12.3576 10.2238 12.3468C10.7202 12.3359 11.2054 12.3032 11.7017 12.2487C11.8341 12.2378 11.9554 12.2378 12.0216 12.3685C12.0877 12.5101 11.9995 12.5973 11.9003 12.6844C11.6025 12.9132 11.2606 12.9785 10.8966 13.0003C10.5768 13.0003 10.2459 12.9894 9.92606 12.9894Z" fill="#333333"/>
    </svg>
  );
};
export default MultipleOasi;
